import './App.css';

function App() {
  return (
    <div className="ui container">
      <h2 className="ui">
        <img class="ui image" src="/images/e_logo.png" />
        <div className="content">
          Allt om väder, vind, klimatet, naturkatastrofer och framförallt åska med blixtar.
        </div>
      </h2>
      <div className="ui sizer vertical segment">
        <div className="ui tiny header">13 december 2020</div>
        <p>
          3 år senare, kanske något är på gång...
        </p>
      </div>
      <div className="ui sizer vertical segment">
        <div className="ui tiny header">13 december 2017</div>
        <p>
          I snart 11 år har Blixtar.se funnits online som ett community. På 11 år har både internet föräldrats teknikmässigt men du som besökare har även förändrat dina vanor.
          Förr i tiden så besökte man sin lista på bokmärken, nu surfar man mest in på Facebook och följer där företag och ämnen man är intresserade av.
          Jag är likadan som de flesta och i ärligehetens namn så har inte jag heller besökt denna sajt speciellt ofta.
          Men sedan något år tillbaka så har det funnits tankar om att ändå göra något roligt av Blixtar.se. För vi är rätt många som är intresserade av åska och oväder.
        </p>
        <p>
          Så det finns både planer och vissa påbörjade experiment för att göra om Blixtar.se till något häftigare.
        </p>
        <p>
          Följ <a href="https://www.facebook.com/Blixtar/">Blixtar.se på Facebook</a> för att inte missa när och om vi kommer igång igen.
        </p>
        <p>
          Idag så ville inte sajten vara med längre, så därav så passar vi på att säga tack till mjukvaran <i>e107</i> som drev sajten i 11 år.
        </p>
        
          
        <p>
          Jag kan få igång någon form av "chatruta" om så önskas så länge. Hör av er till undertecknad. Eposten slutar på @blixtar.se med mitt förnamn före.
          <div>
          Tack för ditt engagemang tills dagens datum. Vi hörs framöver!
          <div className="">~Johannes</div>
          </div>
        </p>

      </div>
    </div>
  );
}

export default App;
